import React, {useEffect, useRef} from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import Banner from "../../components/banner"
import renta1 from "../../../static/img/renta-fija/renta1.jpg"
import renta2 from "../../../static/img/renta-fija/renta2.jpg"
import renta3 from "../../../static/img/renta-fija/renta3.jpg"

const Contenido = (props) => {
    const isMountedComponent = useRef(true)
    useEffect(() => {
        if (isMountedComponent.current) {
            window.jquery_scripts()
        }
        return () => {
            isMountedComponent.current = false
        }
    })
    return (
        <Layout location={props.location}>
            <SEO title="Programa de renta fija"/>
            <Banner title="Programa de renta fija"/>


            <section className="about_consulting_area bg-white pt-5 pb-5">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-7 order-1 order-lg-0">
                            <div className="ex_content">
                                <h3>Perfil del cliente</h3>

                                <p>Una empresa del sector construcción ha dado un
                                    impulso
                                    significativo a su plan de negocios, a través de la colocación de un eurobono
                                    registrado
                                    en los Estados
                                    Unidos. Por lo tanto, basándose en su conocimiento de los mercados financieros, la
                                    compañía desea
                                    brindar los mejores servicios para inversionistas a sus tenedores de bonos, a la par
                                    de
                                    empezar a
                                    desarrollar mayores capacidades en relaciones con inversionistas y gobierno
                                    corporativo
                                    que le permita
                                    una subsecuente colocación de eurobonos, o bien, llevar a cabo una colocación en el
                                    mercado de capitales
                                    en el mediano plazo.</p>
                            </div>
                        </div>
                        <div className="col-lg-5 order-0 order-lg-1 text-center">
                            <img className="video_img wow fadeInRight" src={renta1} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
            <section className="about_consulting_area bg-white">
                <div className="container">
                    <div className="row strategy_info">
                        <div className="col-lg-5">
                            <img className="video_img w-100 wow fadeInLeft" src={renta2} alt={" "}/>
                        </div>
                        <div className="col-lg-7">
                            <div className="ex_content">
                                <h3>Mandato</h3>

                                <p>Después de haber conducido un beauty contest entre
                                    las diferentes
                                    opciones de asesoría en RI en México, se optó por contratar la solución de Renta
                                    Fija a
                                    la medida de
                                    IRStrat, con el mandato de aumentar la agilidad de ejecución de una subsecuente
                                    colocación de deuda, y
                                    preparar las bases iniciales para la ejecución de un posible IPO.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="pt-5 pb-0">
                <div className="container ex_content">
                    <div className="row m-0">
                        <div className="col-md-12 pl-3 pl-lg-0">
                            <h3 className="s_title d-block">Solución</h3>
                        </div>
                    </div>
                </div>
            </section>
            <section className="executive_area pt-3 pb-4">
                <div className="container">
                    <div className="row step service_right_sidebar p-0 mb-5 d-flex align-items-center">
                        <div className="col-lg-7">
                            <div id="accordion7" role="tablist" aria-multiselectable="true"
                                 className="panel-group faq-accordion service_accordion">
                                <div className="card active">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a data-toggle="collapse" data-parent="#accordion7" href="#collapse25"
                                               className="btn-accordion"
                                               aria-expanded="true" role="button" aria-controls="collapse25">
                                                <span className="pluse">+</span><span className="minus">-</span>Estrategia
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse25" className="collapse show" aria-expanded="true" role="tabpanel">
                                        <div className="card-block panel-body"><p>Nuestro equipo, de la mano del equipo
                                            directivo, comienza a
                                            perfeccionar la tesis de inversión, historia y ventaja competitiva de la
                                            Compañía, así como el
                                            estilo de comunicación (mix cualitativo y cuantitativo), una óptima
                                            estrategia de prospección y
                                            divulgación, y una agenda intensiva de PR financiero. Asimismo, se
                                            estructuran las políticas de
                                            divulgación y se redefinen procesos, funciones y KPIs de RI.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse26"
                                               className="btn-accordion collapsed" aria-expanded="false"
                                               aria-controls="collapse26">
                                                <span className="pluse">+</span><span className="minus">-</span>Táctica
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse26" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body"><p>IRStrat conduce un entrenamiento exhaustivo del
                                            equipo ejecutivo de
                                            nuestro cliente en comunicación financiera, y se desarrollan las capacidades
                                            necesarias para la
                                            realización de webcasts trimestrales de resultados. Asimismo, se prepara una
                                            agenda intensiva de
                                            lobbying y PR financiero, y se implementa una estrategia de exposición
                                            mediática enfocada hacia
                                            los medios financieros especializados más influyentes.</p>
                                            <p>IRStrat desarrolla a conciencia todos los materiales de RI para
                                                garantizar una calidad óptima,
                                                incluyendo el reporte trimestral de resultados, reporte e informe anual,
                                                presentación a
                                                inversionistas, sitio de RI e investment fact sheet. Asimismo, se
                                                desarrolla y comunica
                                                ampliamente una guía de resultados de corto y largo plazo.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" role="tab">
                                        <h4 className="panel-title">
                                            <a role="button" data-toggle="collapse" data-parent="#accordion7"
                                               href="#collapse27"
                                               className="btn-accordion collapsed" aria-expanded="false">
                                                <span className="pluse">+</span><span className="minus">-</span>Resultados
                                            </a>
                                        </h4>
                                    </div>
                                    <div id="collapse27" className="panel-collapse collapse" aria-expanded="false"
                                         role="tabpanel">
                                        <div className="panel-body">Nuestro cliente es reconocido como una empresa líder
                                            en relaciones con
                                            inversionistas y mejores prácticas de gobierno corporativo, con un
                                            posicionamiento único y
                                            visibilidad en los mercados. En consecuencia, se presentan nuevas
                                            oportunidades para una
                                            subsecuente emisión de eurobonos, bajo términos y condiciones mucho más
                                            favorables que las
                                            colocaciones anteriores, tanto en tasas de interés y como en vencimientos.
                                            De igual manera, los
                                            estudios de percepción conducidos por IRStrat muestran una retroalimentación
                                            muy positiva de los
                                            mercados con respecto a una posible OPI.
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="col-lg-5 text-center mt-4 mt-lg-0">
                            <img className="img-fluid w-100 wow fadeInRight" src={renta3} alt={" "}/>
                        </div>
                    </div>
                </div>
            </section>
        </Layout>
    )
}

export default Contenido
